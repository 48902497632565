import dayjs from 'dayjs';
import { MdInfo } from 'react-icons/md';

const TimezoneWarning = ({
  label = 'Les horaires proposés sont à l’heure de Paris (CET/CEST)',
}) => {
  const localOffset = dayjs().local().format('ZZ');
  const appOffset = dayjs().tz().format('ZZ');
  if (localOffset !== appOffset) {
    return (
      <div className="flex p-4 text-sm items-center my-6 border border-violet-500 bg-violet-100 rounded-md text-black gap-4">
        <MdInfo className="h-5 w-5 flex-shrink-0 text-violet-500" />
        <span>{label}</span>
      </div>
    );
  }
  return null;
};

export default TimezoneWarning;

import Axios from 'axios';
import {
  STATUS_LOADING,
  STATUS_RETOUR,
  STATUS_ERROR,
} from '../reducers/statusReducer';
import { API_URL } from '../../config/config';

const submitClaim = (dataToSend, path) => (dispatch) => {
  dispatch({ type: STATUS_LOADING });
  return Axios.post(
    API_URL + path,
    { ...dataToSend },
    { withCredentials: true }
  )
    .then(({ data }) => {
      if (data.claim?.number) {
        dispatch({ type: STATUS_RETOUR });
        return data.claim;
      }
      dispatch({
        type: STATUS_ERROR,
        message:
          data.message ||
          data.error ||
          'Erreur de transmission des données, nos équipes sont prévenues, veuillez ré-essayer plus tard.',
      });
      return null;
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: STATUS_ERROR,
        message:
          err.response?.data?.error ||
          err.response?.data?.message ||
          'Erreur de transmission des données, nos équipes sont prévenues, veuillez ré-essayer plus tard.',
      });
    });
};

export default { submitClaim };

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const ShowOnTime = ({ min, max, inProps, outProps, children }) => {
  const [timer, setTimer] = useState(new Date());
  useEffect(() => {
    setTimeout(() => {
      setTimer(new Date());
    }, 1000);
  }, [timer]);

  const props =
    dayjs().isAfter(dayjs(min)) && dayjs().isBefore(dayjs(max))
      ? inProps
      : outProps;

  return React.Children.map(children, (Child) => {
    if (Child === null) return <> </>;

    return React.cloneElement(Child, props);
  });
};

export default ShowOnTime;

import ClipLoader from 'react-spinners/ClipLoader';

const StepController = (props) => (
  <div className="mt-6">
    <button
      disabled={!props.canSubmit || props.loading || false}
      onClick={props.onNext === 'submit' ? () => {} : props.onNext}
      type={props.onNext === 'submit' ? 'submit' : 'button'}
      className="btn my-2 w-full"
    >
      {props.nextLabel || 'Suivant'}{' '}
      {props.loading ? (
        <ClipLoader size="20px" cssOverride={{ float: 'right' }} />
      ) : (
        ''
      )}
    </button>
  </div>
);

export default StepController;

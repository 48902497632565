import Modal from 'react-responsive-modal';

const ConfirmModal = ({
  children,
  onClose,
  onConfirm,
  cancelLabel = 'Annuler',
  confirmLabel = 'Confirmer',
}) => (
  <Modal open onClose={onClose} classNames={{ modal: 'w-80' }} center>
    {children}
    <div className="flex justify-end w-full mt-4 pt-4 border-t border-grey-300 gap-2">
      <button
        type="button"
        onClick={onClose}
        className="btn bg-grey-300 hover:bg-slate-300 font-thin w-1/2"
      >
        {cancelLabel}
      </button>
      <button type="button" className="btn font-thin w-1/2" onClick={onConfirm}>
        {confirmLabel}
      </button>
    </div>
  </Modal>
);

export default ConfirmModal;

import { Translation } from 'react-i18next';

const FileHelper = () => (
  <Translation ns="form">
    {(t) => (
      <div>
        {t('Fournir de préférence un fichier aux formats')} <b>pdf</b>/
        <b>jpg</b>.
        <br />
        <br />
        {t(
          "Si vous voulez réduire le temps d'envoi, vous pouvez optimiser vos fichiers grace à ces outils gratuits :"
        )}
        <div>
          PDF :{' '}
          <a
            className="text-caarlblue underline"
            href="https://shrinkpdf.com/"
            rel="noreferrer"
            target="_blank"
          >
            Shrink PDF
          </a>
        </div>
        <div>
          JPG :{' '}
          <a
            className="text-caarlblue underline"
            href="https://compressjpeg.com/"
            rel="noreferrer"
            target="_blank"
          >
            Compress JPEG
          </a>
        </div>
      </div>
    )}
  </Translation>
);

export default FileHelper;

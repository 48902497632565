import { specialitiesDef } from '../../../types/claimdata';

export const getSpecialityFromDefinitions = (
  specName: string,
  specialitiesDefinitions: specialitiesDef
) => {
  const [level1, level2] = specName.split('_');
  if (!level2) return specialitiesDefinitions[specName];
  return specialitiesDefinitions[level1]?.children[specName];
};

export const test = 'lol';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { FaRegCalendar, FaRegClock } from 'react-icons/fa';

const DateHead = ({ datetime }) => {
  const { t } = useTranslation('form');
  return (
    <div className="flex items-center justify-between">
      <div>
        <FaRegCalendar className="inline align-middle text-lg mr-2 h-6 w-6" />{' '}
        <span className="capitalize align-middle leading-none">
          {dayjs(datetime).tz().format(t('dateFormat'))}
        </span>
      </div>
      <div>
        <FaRegClock className="inline align-middle text-lg ml-8 mr-2 h-6 w-6" />{' '}
        <span className="capitalize align-middle leading-none">
          {dayjs(datetime).tz().format('HH:mm')}
        </span>
      </div>
    </div>
  );
};

export default DateHead;

import React, { useRef } from 'react';

const MeetingModal = ({ roomURL, onClose }) => {
  const container = useRef(null);
  return (
    <div
      className="relative z-50 before:fixed before:inset-0 before:bg-grey-100 before:bg-opacity-75 before:transition-opacity"
      role="dialog"
      aria-labelledby="modal-title"
      aria-modal="true"
    >
      <div
        className="fixed z-10 inset-0 p-8 overflow-y-auto"
        ref={container}
        aria-hidden="true"
        onClick={(e) => {
          if (container.current === e.target) onClose();
        }}
      >
        <div className="relative flex flex-col items-stretch min-h-full p-4 w-full bg-white text-left sm:p-0">
          <iframe
            className="absolute top-0 left-0 right-0 bottom-0 z-10 w-full h-full"
            title="roomURL"
            src={roomURL}
            allow="camera; microphone; fullscreen; speaker; display-capture"
          />
          <button
            type="button"
            onClick={onClose}
            className="absolute z-20 top-1 right-1 text-white bg-black border-white border-2 text-2xl rounded-full h-10 w-10 table-cell align-middle"
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  );
};
export default MeetingModal;

import { useTranslation } from 'react-i18next';

const NoCredits = () => {
  const { t } = useTranslation('form');
  return (
    <div className="max-w-sm mx-auto py-4  text-base">
      <svg
        fill="none"
        className="stroke-none my-10 w-44 block mx-auto"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 124 124"
      >
        <path
          d="M25.833 98.167A5.166 5.166 0 0 0 31 103.334h62a5.167 5.167 0 0 0 5.167-5.167 5.245 5.245 0 0 0-.93-2.945l-30.07-52.08V20.667H56.833v22.475l-30.07 52.08a5.245 5.245 0 0 0-.93 2.945Zm5.167 15.5a15.5 15.5 0 0 1-15.5-15.5c0-3.1.93-5.993 2.583-8.422L46.5 40.352V31a5.167 5.167 0 0 1-5.167-5.166v-5.167a10.333 10.333 0 0 1 10.334-10.334h20.666a10.333 10.333 0 0 1 10.334 10.334v5.166A5.167 5.167 0 0 1 77.5 31v9.352l28.417 49.393a14.884 14.884 0 0 1 2.583 8.422 15.5 15.5 0 0 1-15.5 15.5H31Z"
          fill="#797E8B"
        />
      </svg>
      <p>{t('no-credits-1')}</p>

      <p className="mt-4">{t('no-credits-2')}</p>
    </div>
  );
};

export default NoCredits;
